import React, { Component } from 'react'
import './App.css'
import logo from './logo-dark.png'
import images from './images'

class App extends Component {
    scrollTo = id => {
        document.getElementById(id).scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        })
    }
    
    render() {      
        return (
            <div className="App">
                <div className="menu">
                    <button onClick={() => this.scrollTo('top')}>
                        Qui sommes-nous ?
                    </button>
                    <div>⬥</div>
                    <button onClick={() => this.scrollTo('realisations')}>
                        Nos réalisations
                    </button>
                    <div>⬥</div>
                    <button onClick={() => this.scrollTo('contact')}>
                        Nous contacter
                    </button>
                    <a href="tel:0140313006">01 40 31 30 06</a>
                </div>
                <div id="top" className="top">
                    <img src={logo} alt="logo" />
                    <div className="legend">
                        Le rangement devient un plaisir
                    </div>
                    <div className="detail">
                        Fabrication de rangements sur mesure. <br /> Nos
                        spécialistes se tiennent à votre disposition pour
                        réaliser vos projets, et modifier agréablement
                        l'aménagement de votre intérieur.
                    </div>
                </div>
                <div id="realisations" className="realisations">
                    <h2>Nos réalisations</h2>
                    <div
                        id="carousel"
                        className="carousel slide carousel-fade"
                        data-ride="carousel">
                        <div className="carousel-inner">
                            {images.map((img, i) => (
                                <div
                                    key={i}
                                    style={{ backgroundImage: `url(${img})` }}
                                    className={
                                        i === 1
                                            ? 'carousel-item active'
                                            : 'carousel-item'
                                    }
                                />
                            ))}
                        </div>
                        <a
                            className="carousel-control-prev"
                            href="#carousel"
                            role="button"
                            data-slide="prev">
                            <span
                                className="carousel-control-prev-icon"
                                aria-hidden="true"
                            />
                            <span className="sr-only">Previous</span>
                        </a>
                        <a
                            className="carousel-control-next"
                            href="#carousel"
                            role="button"
                            data-slide="next">
                            <span
                                className="carousel-control-next-icon"
                                aria-hidden="true"
                            />
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>
                <div id="contact" className="contact-form">
                    <h2>Nous contacter</h2>
                    <div className="contact-container">
                        <form
                            action="./mailer.php"
                            method="POST"
                            encType="multipart/form-data">
                            <div className="form-row">
                                <input
                                    name="name"
                                    id="name"
                                    type="text"
                                    placeholder="Nom"
                                />
                                <input
                                    name="email"
                                    id="email"
                                    type="text"
                                    placeholder="Email"
                                />
                                <input
                                    name="phone"
                                    id="phone"
                                    type="text"
                                    placeholder="Téléphone"
                                />
                                <textarea
                                    name="description"
                                    id="description"
                                    type="text"
                                    rows="5"
                                    placeholder="Décrivez-nous votre projet"
                                />
                                <input
                                    id="file"
                                    name="file"
                                    type="file"
                                    className="file"
                                />
                                <button type="submit" className="btnn">
                                    Envoyer
                                </button>
                            </div>
                        </form>
                        <div className="infos">
                            <div>Venez découvrir notre showroom:</div>
                            <div>151 Boulevard Davout</div>
                            <div>75020 Paris</div>
                            <a href="tel:0140313006">01 40 31 30 06</a>
                            <a href="tel:0699455959">06 99 45 59 59</a>
                            <div className="gmap_canvas">
                                <iframe
                                    title="map"
                                    id="gmap_canvas"
                                    src="https://maps.google.com/maps?q=151%20Boulevard%20Davout%2075020%20Paris&t=&z=15&ie=UTF8&iwloc=&output=embed"
                                    frameBorder="0"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    Les pros du placard © Tous droits réservés <br />
                    ATELIER DES PROS <br />
                    151 Boulevard Davout 75020 Paris <br />
                    Tel: 01.40.31.30.06 Portable: 06.99.45.59.59 Fax:
                    09.55.72.81.00 <br />
                    SIRET 79967145800013 <br />
                    RCS Paris 799 671 458 <br />
                    TVA intra-communautaire FR 07 799671458 <br />
                    Société par actions simplifiée au capital de 260 € <br />
                </div>
            </div>
        )
    }
}

export default App
