import img1 from './img/P1.jpg';
import img2 from './img/P2.jpg';
import img3 from './img/P3.jpg';
import img4 from './img/P4.jpg';
import img5 from './img/P5.jpg';
import img6 from './img/P6.jpg';
import img7 from './img/P7.jpg';
import img8 from './img/P8.jpg';
import img9 from './img/P9.jpg';
import img10 from './img/P10.jpg';



const images = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
]

export default images;